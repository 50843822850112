@import 'src/scss/_variables.scss';

.dialog-title {
  text-align: center;
  font-weight: 800;
  color: $primary;
}
.dialog-subtitle {
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  font-size: 15px;
}

.dialog-additional-feedback {
  color: $primary;
  font-weight: 800;
  border-top: 1px solid $grey400;
}

.dialog-submit {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
