@use 'src/scss/_variables.scss' as variables;

.container-footer-desktop {
  max-width: variables.$siteLayoutWidth;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  .footer-desktop {
    display: flex;
    justify-content: space-between;
    padding: 2rem 2rem;
    width: 100%;

    .footer-left-item {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .container-social-icon {
        display: flex;
        gap: 2rem;
      }
    }

    .footer-right-item {
      display: flex;
      gap: 2rem;
      margin-top: auto;
      margin-bottom: auto;

      .footer-pages-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}
