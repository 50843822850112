// Global variables
$primary: #008cdc;
$primaryLight: #1e95da;
$primaryDark: #007abf;
$success: #009c6a;
$danger: #fa6d78;
$textPrimary: #59595c;
$textSecondary: #1e91cf;
$textLight: #b1b1b1;
$bgLight: #f1f1f3;

$white: #ffffff;
$green: #00a530;
$grey100: #f5f6f9;
$grey200: #edf2f7;
$grey300: #e2e8f0;
$grey400: #cbd5e0;
$grey500: #a0aec0;
$grey600: #718096;
$grey700: #4a5568;
$grey800: #2d3748;
$grey900: #1a202c;

$backgroundGrey: #f1f1f2;

$siteLayoutWidth: 1200px;
$siteContentWidth: 1250px;
