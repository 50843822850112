@mixin hide-scrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }
}

$chat-item-hovered-color: #baddf3;

.container-chat-page {
  display: grid;
  grid-template-columns: 3fr 7fr;
  width: 100%;
  height: 60rem;

  .left-col {
    overflow-y: scroll;
    box-shadow: 3px 0 8px -2px rgb(0 0 0 / 20%);
    background-color: #f4f7fa;

    .container-chat-item {
      padding: 0.5rem 1.5rem 1rem 1.5rem;
      position: relative;
      border-bottom: 1px solid #00000029;
      cursor: pointer;

      &:hover {
        background-color: $chat-item-hovered-color;
      }

      &.selected {
        background-color: $chat-item-hovered-color;
      }
    }

    @include hide-scrollbar;
  }

  .right-col {
    padding: 1.5rem 3rem;
    overflow-y: scroll;

    @include hide-scrollbar;
  }
}

.chatpage-desktop-container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 4rem;
}
