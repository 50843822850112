.parcel-history-section {
    overflow: auto;
    max-height: 25rem;

    @media only screen and (min-width: 960px) {
      height: auto;
      max-height: 26rem;
      margin-right: 0.5rem;
    }

    .MuiCardContent-root {
      display: flex;
    }

    .parcel-progress {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 0.5rem 2.3rem 0;
    
      .progress-item {
          height: 2.5rem;
      }

      .dotted-line {
        height: 30%;
        border-left: 0.15rem dashed #cbd5e0;
        margin: auto;
      }
    }
  }