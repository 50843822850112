@import 'src/scss/_variables.scss';

.faq-page-paper {
  .left-section {
    box-shadow: 3px 0 8px -2px rgb(0 0 0 / 20%);
    padding: 1rem 0;
  }

  .right-section {
    padding: 2rem;

    .faq-box {
      margin: 0 4rem 0 3rem;

      .topic {
        color: $primary;
        text-align: left;
        font-size: 1rem;
        margin: 10px 0;
      }

      .faq-answer {
        font-size: 1rem;
        text-align: justify;
        text-justify: inter-word;
      }

      .faq-link {
        word-wrap: break-word;
      }

      .faq-bullet-list {
        font-size: 0.9rem;
      }
    }
  }
}
