@import 'src/scss/_variables.scss';

.container-header-desktop-view {
  max-width: $siteLayoutWidth;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  .header-desktop {
    display: flex;
    justify-content: space-between;
    padding: 3rem 2rem;
    width: 100%;

    .logo-fm {
      cursor: pointer;
    }
  }
}
