.lFooter {
  flex: 1 380px;
  text-align: center;
  text-decoration: none;
  color: #FFF;
  font-size: 12px;
  font-family: 'sans-serif', 'helvetica' !important;

  @media (min-width: 800px) {
    text-align: left;
  }
}

.rFooter {
  flex: 1 380px;
  text-align: center;
  font-family: 'sans-serif', 'helvetica' !important;

  @media (min-width: 800px) {
    text-align: right;
    max-width: 400px;
  }
}