@use 'src/scss/_variables' as variables;

.desktop-layout {
  background-color: variables.$white;
  .container-welcome-message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15rem;
    background-color: variables.$primary;
    color: variables.$white;
    font-size: 38px;
  }

  .container-site-content {
    // max-width: variables.$siteContentWidth;
    display: flex;
    justify-content: center;
    background-color: variables.$backgroundGrey;

    .inner-container-site-content {
      max-width: variables.$siteContentWidth;
      width: 100%;
      padding: 2rem 1.5rem 5rem 1.5rem;
      display: flex;
      flex-direction: column;

      .breadcrumbs-site-content {
        margin-bottom: 2rem;
        display: flex;
        gap: 1rem;

        .MuiTypography-body1 {
          font-size: 16px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
