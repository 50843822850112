@mixin hide-scrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }
}

$order-item-hovered-color: #baddf3;

.container-order-page {
  display: grid;
  grid-template-columns: 3fr 7fr;
  width: 100%;
  height: 50rem;

  .left-col {
    // border-right: 3px solid #00000029;
    overflow-y: scroll;
    background-color: #f4f7fa;
    box-shadow: 3px 0 8px -2px rgb(0 0 0 / 20%);

    @include hide-scrollbar;

    .container-order-item {
      padding: 1rem 1.5rem 1rem 1.5rem;
      position: relative;
      border-bottom: 1px solid #00000029;
      cursor: pointer;

      &:hover {
        background-color: $order-item-hovered-color;
      }

      &.selected {
        background-color: $order-item-hovered-color;
      }
    }
  }

  .right-col {
    @include hide-scrollbar;
    padding-left: 1rem;
    overflow-y: scroll;

    .empty{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }
}
