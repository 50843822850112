@import 'src/scss/_variables.scss';

.landing-page-content {
    // @extend %shared-home-section;
    margin: 0 0 0 0.5rem;
    // padding-top: 3rem;
    // padding-bottom: 3rem;
    flex-direction: column;
  
    .MuiTypography-h2 {
      // @extend %shared-typography-news;
      width: 100%;
      font-weight: bold;
      font-size: 30px;
      line-height: 2.2rem;
      text-align: center;
    }
    .MuiTypography-h3 {
      // @extend %shared-typography-news;
      width: 100%;
      font-weight: bold;
      font-size: 18px;
      line-height: 2.2rem;
      text-align: center;
    }
    .MuiTypography-h5 {
      // @extend %shared-typography-news;
      width: 100%;
      font-size: 14px;
      margin-top: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: center;
    }
    .card-divider {
      font-size: 0.7rem;
      opacity: 0.7;
    }
    .lg-card-divider {
      font-family: Roboto !important;
      font-size: 1.7rem;
      opacity: 0.7;
    }
    .lg-landing-item-card {
      display: flex;
      flex-direction: row;
      align-items: center;
  
      .landing-item-card {
        width: 80%;
        min-height: 210px;
        background-color: white;
        box-shadow: none;
        border-radius: 10px;
        margin: 3rem 2rem 3rem 0;
  
        .landing-item-card-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 10px;
          padding-top: 15px;
          .icon-container {
            background-color: #008cdc26;
            border-radius: 20px;
            width: 83px;
            height: 83px;
            display: flex;
            justify-content: center;
          }
          .MuiTypography-body1 {
            width: 100%;
            font-size: 1rem;
            margin-top: 1rem;
            font-weight: 400;
            // line-height: 1.5rem;
            text-align: center;
          }

          .text-title {
            color: $textPrimary;
          }
          .text-desr {
            color: $textLight;
          }
        }
      }
    }
    .sm-landing-item-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      .landing-item-card {
        width: 80%;
        background-color: white;
        box-shadow: none;
        border-radius: 10px;
        margin-top: 15px;
  
        .landing-item-card-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 10px;
          padding-top: 15px;
          .icon-container {
            background-color: #008cdc26;
            border-radius: 20px;
            width: 83px;
            height: 83px;
            display: flex;
            justify-content: center;
          }
          .MuiTypography-body1 {
            color: $textPrimary;
            width: 100%;
            font-size: 19px;
            margin-top: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            text-align: center;
          }
        }
      }
    }
  }